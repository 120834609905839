import React from "react";

const WhatsAppTab = () => {
  return (
    <div>
      <h2>WhatsApp</h2>
      <p>
        Открыть WhatsApp:{" "}
        <a href="https://wa.me" target="_blank" rel="noopener noreferrer">
          https://wa.me
        </a>
        wda
      </p>
    </div>
  );
};

export default WhatsAppTab;
